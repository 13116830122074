import React from 'react';
import { Result, Button } from 'antd';
import { SmileOutlined } from '@ant-design/icons';

const LayoutHome = () => {
  return (
    <Result
      icon={<SmileOutlined />}
      title="Bienvenido! La aplicación está en desarrollo..."
      extra={<Button type="primary">Siguiente</Button>}
    />
  );
};

export default LayoutHome;
