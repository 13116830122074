import * as constants from './constants';
import * as api from '../../config';
import axios from 'axios';

/**
 * Feeds Google News
 */
export const handleFeedsGoogleNews = (payload) => (dispatch, getState, axiosHandler) => {
  const {search_data} = payload
  dispatch({
    type: constants.GOOGLE_NEWS_STARTE,
  })
  axiosHandler.getFeeds = async () => {
    await axiosHandler
      .post(api.GOOGLE_NEWS_FEEDS,{search_data})
      .then((res) => {
        console.log('Console: axiosHandler.getOrdenes -> res', res);
        dispatch({
          type: constants.GOOGLE_NEWS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log('Tuvergonzolog: axiosHandler.getOrdenes -> err', err);
      });
  };
  axiosHandler.getFeeds();
};