export const TOGGLED_SIDEBAR = 'TOGGLED_SIDEBAR';

/** Login */
export const USER_LOGIN_STARTED = 'USER_LOGIN_STARTED';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_HAS_LOGOUT = 'USER_HAS_LOGOUT';

/** Usuarios */
export const USERS_QUERIED = 'USERS_QUERIED';

/**
 * Feeds 
 */
export const FEED_URL_SUMARY = 'FEED_URL_SUMARY';
export const FEED_URL_SUMARY_STARTE = 'FEED_URL_SUMARY_STARTE';
export const TIPO_URL_FEED = 'TIPO_URL_FEED';
/**
 * Feeds db local
 */
export const FEED_LOCAL = 'FEED_LOCAL';
export const FEED_LOCAL_STARTE = 'FEED_LOCAL_STARTE';
/**
 * Feeds Google News
 */
export const GOOGLE_NEWS_STARTE = 'GOOGLE_NEWS_STARTE';
export const GOOGLE_NEWS_SUCCESS = 'GOOGLE_NEWS_SUCCESS';
/**
 * Feeds Google News topic
 */
export const GOOGLE_NEWS_TOPIC_STARTE = 'GOOGLE_NEWS_TOPIC_STARTE';
export const GOOGLE_NEWS_TOPIC_SUCCESS = 'GOOGLE_NEWS_TOPIC_SUCCESS';