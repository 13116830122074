import {
  USER_LOGIN_STARTED,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILED,
  USER_HAS_LOGOUT,
} from '../actions/constants';

const initialState = {
  fetching: false,
  label: 'Comenzar',
  success: false,
  notify: false,
  token: null,
};

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case USER_LOGIN_STARTED:
      return {
        ...state,
        fetching: true,
        notify: false,
        label: 'Conectando con el servidor...',
      };
    case USER_LOGIN_SUCCESS:
      return Object.assign({}, state, {
        fetching: false,
        label: `Bienvenido ${action.payload.username}`,
        token: action.payload.token,
        success: true,
        username: action.payload.username,
        role: action.payload.role,
      });
    case USER_LOGIN_FAILED:
      return Object.assign({}, state, {
        fetching: false,
        label: 'Comenzar',
        success: false,
        notify: true,
        notificationMessage: action.payload.message,
        notificationTitle: action.payload.title,
      });
    case USER_HAS_LOGOUT:
      return Object.assign({}, state, {
        fetching: false,
        label: 'Comenzar',
        token: null,
      });
    default:
      return state;
  }
}
