import { FEED_LOCAL_STARTE, FEED_LOCAL } from '../actions/constants'

const initialState = {
   searching: false,
   datos:[{
     title:'',
     summary:'',
   }],
}

export default function feedsReducer(state = initialState, action) {
   switch (action.type) {
      case FEED_LOCAL_STARTE:
      return Object.assign({}, state, {
         searching: true,
      })
      case FEED_LOCAL:
         return Object.assign({}, state, {
            success: true,
            datos: action.payload,
            searching: false,
         })
      default:
         return state
   }
}