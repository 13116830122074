import React from 'react';
import { Row, Col } from 'antd';
import LeftCover from './LeftCover';
import NormalLoginForm from '../../components/access/login';

const AccessLanding = () => (
  <div className="login-container">
    <div className="login-presentation">
      <Row>
        <Col span="12">
          <LeftCover />
        </Col>
        <Col span="12" className="right-form">
          <NormalLoginForm />
        </Col>
      </Row>
    </div>
  </div>
);

export default AccessLanding;
