import React from 'react';
import { Card, Col, Row } from 'antd';
import userCover from '../../../styles/images/users.jpg';
import locationCover from '../../../styles/images/location.jpg';
import pdfCover from '../../../styles/images/pdf.jpg';

const { Meta } = Card;

const Catalogos = (props) => {
  const { history } = props;

  const handleClick = (path) => {
    history.push(`/app/admin/${path}`);
  };
  return (
    <div className="site-card-wrapper">
      <Row gutter={16}>
        <Col span={8}>
          <Card
            hoverable
            style={{ width: 240 }}
            cover={<img alt="example" src={userCover} />}
            onClick={() => {
              handleClick('usuarios');
            }}
          >
            <Meta
              title="Usuarios"
              description="Consulta y gestiona todos los usuarios del sistema Adler."
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            hoverable
            style={{ width: 240 }}
            cover={<img alt="example" src={locationCover} />}
          >
            <Meta title="Ubicaciones" description="Catálogo de ubicaciones." />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            hoverable
            style={{ width: 240 }}
            cover={<img alt="example" src={pdfCover} />}
          >
            <Meta
              title="Formatos"
              description="Descarga cualquier formato en PDF."
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Catalogos;
