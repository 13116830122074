import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Layout, Menu, Breadcrumb, Avatar, Dropdown } from 'antd';
import { withRouter } from 'react-router-dom';

import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  AppstoreTwoTone,
  PieChartTwoTone,
  ExperimentTwoTone,
  GoldTwoTone,
  EyeTwoTone,
  ControlOutlined,
  AppstoreOutlined,
} from '@ant-design/icons';

import {
  handleCollapseSidebar,
  handleUserLogout,
} from '../../redux/actions/creators';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class Dashboard extends React.Component {
  onCollapse = () => {
    const { onCollapseSidebar, collapsed } = this.props;

    onCollapseSidebar(collapsed);
  };

  handleSideMenuClick = (path) => {
    const { history } = this.props;

    history.push(`/app/${path}`);
  };

  userLogout = () => {
    const { onUserLogout, history } = this.props;

    onUserLogout();

    history.push('/');
    window.location.href = '/';
  };

  render() {
    const { collapsed, author, year, children, username } = this.props;

    const menu = (
      <Menu>
        <Menu.Item>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.alipay.com/"
          >
            1st menu item
          </a>
        </Menu.Item>
        <Menu.Item>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.taobao.com/"
          >
            2nd menu item
          </a>
        </Menu.Item>
        <Menu.Item>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.tmall.com/"
          >
            3rd menu item
          </a>
        </Menu.Item>
        <Menu.Item
          danger
          onClick={() => {
            this.userLogout();
          }}
        >
          Salir
        </Menu.Item>
      </Menu>
    );

    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse}>
          <div className="logo" />
          <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
            <Menu.Item key="0" icon={<PieChartTwoTone />}>
              Dashboard
            </Menu.Item>
            <SubMenu key="sub1" icon={<AppstoreTwoTone />} title="Feed">
              <Menu.Item
                key="1"
                icon={<PieChartOutlined />}
                onClick={() => {
                  this.handleSideMenuClick('feed');
                }}
              >
                Feeds
              </Menu.Item>
              {/* <Menu.Item
                key="1"
                icon={<PieChartOutlined />}
                onClick={() => {
                  this.handleSideMenuClick('feed');
                }}
              >
                Feeds
              </Menu.Item> */}
            </SubMenu>
            <SubMenu key="sub2" icon={<AppstoreTwoTone />} title="Google News">
              <Menu.Item
                key="2"
                icon={<PieChartOutlined />}
                onClick={() => {
                  this.handleSideMenuClick('news');
                }}
              >
                Search
              </Menu.Item>
              <Menu.Item
                key="2.2"
                icon={<PieChartOutlined />}
                onClick={() => {
                  this.handleSideMenuClick('topic');
                }}
              >
                Topic
              </Menu.Item>
            </SubMenu>
            {/* <SubMenu
              key="sub2"
              icon={<ExperimentTwoTone />}
              title="Farmacéuticos"
            >
              <Menu.Item key="2" icon={<PieChartTwoTone />}>
                Ordenes
              </Menu.Item>
            </SubMenu>
            <SubMenu key="sub3" icon={<ExperimentTwoTone />} title="Virología">
              <Menu.Item key="3">Ordenes</Menu.Item>
            </SubMenu>
            <SubMenu key="sub4" icon={<ExperimentTwoTone />} title="Biológicos">
              <Menu.Item key="4">Ordenes</Menu.Item>
            </SubMenu>
            <SubMenu key="sub5" icon={<EyeTwoTone />} title="Calidad">
              <Menu.Item key="5">Ordenes</Menu.Item>
            </SubMenu>
            <SubMenu key="sub6" icon={<GoldTwoTone />} title="Acondicionado">
              <Menu.Item key="6">Ordenes</Menu.Item>
            </SubMenu> */}
            {/* <SubMenu
              key="admin_users"
              icon={<ControlOutlined />}
              title="Herramientas"
            >
              <Menu.Item
                key="6"
                icon={<AppstoreOutlined />}
                onClick={() => {
                  this.handleSideMenuClick('admin/catalogos');
                }}
              >
                Catálogos
              </Menu.Item>
            </SubMenu> */}
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{ padding: 0, textAlign: 'right', paddingInlineEnd: '10px' }}
          >
            <Dropdown overlay={menu}>
              <Avatar
                size={40}
                style={{ color: '#fff', backgroundColor: '#1890ff' }}
              >
                S
              </Avatar>
            </Dropdown>
          </Header>
          <Content style={{ margin: '0 16px' }}>
            {/* <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>User</Breadcrumb.Item>
              <Breadcrumb.Item>Bill</Breadcrumb.Item>
            </Breadcrumb> */}
            <div
              className="site-layout-background"
              style={{ padding: 24, minHeight: 360 }}
            >
              {children}
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            {author} {year}
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    collapsed: state.layout.collapsed,
    author: state.layout.author,
    year: state.layout.year,
    username: state.login.username,
    role: state.login.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onCollapseSidebar: handleCollapseSidebar,
      onUserLogout: handleUserLogout,
    },
    dispatch
  );
};

const DashboardWithRouter = withRouter(Dashboard);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardWithRouter);
