import { combineReducers } from 'redux';

import layout from './layout';
import login from './login';
import feed from './feed'
import feeds from './feeds'
import googlenews from './googlenews'
import topic from './topic'

export default function createInitialReducers() {
  const rootReducer = combineReducers({
    layout,
    login,
    feed,
    feeds,
    googlenews,
    topic,
  });

  return rootReducer;
}
