import { FEED_URL_SUMARY, FEED_URL_SUMARY_STARTE, TIPO_URL_FEED } from '../actions/constants'

const initialState = {
   searching: false,
   datos:[{
     title:'',
     summary:[]
   }],
   tipo:'url'
}

export default function feedsReducer(state = initialState, action) {
   switch (action.type) {
      case FEED_URL_SUMARY_STARTE:
      return Object.assign({}, state, {
         searching: true,
      })
      case TIPO_URL_FEED:
         return Object.assign({}, state, {
          tipo:action.payload
         })
      case FEED_URL_SUMARY:
         return Object.assign({}, state, {
            success: true,
            datos: action.payload,
            searching: false,
         })
      default:
         return state
   }
}