import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import './index.css';
import BaseLayout from './containers/layout';
import axios from 'axios';
import { SERVER_API } from './config';
import * as serviceWorker from './serviceWorker';

import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';

import logger from 'redux-logger';
import thunk from 'redux-thunk';

import createInitialReducers from './redux/reducers';

const axiosHandler = axios.create({
  baseURL: SERVER_API,
  timeout: 1000000,
  headers: { 'Content-Type': 'application/json' },
});

const store = createStore(
  createInitialReducers(),
  applyMiddleware(logger, thunk.withExtraArgument(axiosHandler))
);

ReactDOM.render(
  <Provider store={store}>
    <BaseLayout />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
