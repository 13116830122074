import { TOGGLED_SIDEBAR } from '../actions/constants';

const initialState = {
  collapsed: false,
  author: 'Marmacore Solutions',
  year: '2020',
  version: '1',
  releae: '1.1',
};

export default function layoutReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLED_SIDEBAR:
      return { ...state, collapsed: !action.payload };
    default:
      return state;
  }
}
