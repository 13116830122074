import { 
  GOOGLE_NEWS_TOPIC_STARTE, 
  GOOGLE_NEWS_TOPIC_SUCCESS
} from '../actions/constants'

const initialState = {
   searching: false,
   topic:'economy',
   datos_googlenews_topic:[{
     title:'',
     summary:'',
   }],
}

export default function feedsReducer(state = initialState, action) {
   switch (action.type) {
      case GOOGLE_NEWS_TOPIC_STARTE:
      return Object.assign({}, state, {
         searching: true,
      })
      case GOOGLE_NEWS_TOPIC_SUCCESS:
         return Object.assign({}, state, {
            success: true,
            datos_googlenews_topic:action.payload,
            searching: false,
            topic:action.topic
         })
      default:
         return state
   }
}