import React, { useEffect, useState} from 'react';
import { Spin, Space, Input, Radio, Card, List, Typography, Drawer, Row, Col, Avatar, message } from 'antd';
import { connect } from 'react-redux';
import CustomTable from '../../components/DataDisplay/Table';
import { bindActionCreators } from 'redux';
import { handleFeedsGoogleNewsTopic } from '../../redux/actions/topic';
import { CheckCircleTwoTone, EllipsisOutlined, SettingOutlined, EyeOutlined, CopyOutlined, AntDesignOutlined, StarOutlined, LikeOutlined, MessageOutlined } from '@ant-design/icons';
import img from './../../styles/images/5985.jpg';


const GoogleNewsTopic = (props) => {
  const {Search} = Input
  const { Meta } = Card;
  const { Title, Paragraph, Text } = Typography;
  const { searching, onPageLoad, datos, tipo, onChangeTipo, dataGoogleNewsTopic, FeedsGoogleNewsTopic, topic } = props;
  // console.log('Tuvergonzolog: Ordenes -> datos',  datos);
  const [visible, setVisible] = useState(false);
  const [summary, setSummary] = useState('');
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const [date, setDate] = useState('');
  //const [topic, setTopic] = useState('economy');

  //onPageLoad()
  useEffect(() => {
    // dataGoogleNews({search_data:''});
    dataGoogleNewsTopic(topic);
  }, []);
  const showDrawer = (feed,title_,url_, date_) => {
    // console.log('TuvergonzoLog: showDrawer -> feed', title_)
    setDate(date_);
    setUrl(url_);
    setTitle(title_);
    setSummary(feed);
    setVisible(!visible);
  };
  const onClose = () => {
    showDrawer();
  }
  const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );
  const validatetopic = (value) =>{
    if(value===''){
      message.error('Ingrese el Topic');
    }else{
      dataGoogleNewsTopic(value)
    }
  }
  return (
    <Spin tip="Cargando..." spinning={searching}>
      {/* <Radio.Group onChange={(e)=>{onChangeTipo(e.target.value)}} defaultValue={tipo} buttonStyle="solid">
        <Radio.Button   value="url">URL</Radio.Button>
        <Radio.Button value="feed">Feed</Radio.Button>
      </Radio.Group> */}
      <div className="searchBar">
        <Search
          placeholder="Ingrese Topic"
          enterButton="Buscar"
          size="large"
          rules={[{ required: true, message: 'Please input your username!' }]}
          onSearch={(value) => validatetopic(value)}
        />
      </div>
      <br></br>

        <Title level={3}>Topic: {topic}</Title> 

      {/* <CustomTable columns={columns}
      expandable={{
        expandedRowRender: record => <p style={{ margin: 0 }}>{record.summary}</p>,
        rowExpandable: record => record.title !== 'Not Expandable',
      }}
      data={datos} /> */}
    <Drawer
      title="Note"
      width={600}
      onClose={() => onClose()}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          {/* <!--Footer> */}
        </div>
      }
    >
      <Row>
        <Col span={24}>

        <Paragraph>
         <Title level={4}>Title:</Title>
        <Title level={5}>{title}</Title>
        <div>Date:{date}</div>
        </Paragraph>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
        <Paragraph>
        <Title level={4}>Summary:</Title>
          <div dangerouslySetInnerHTML={{__html: summary}} />
        </Paragraph>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Paragraph>
            <Title level={4}>URL</Title>
          <Title level={5}><a  target="_blank" href={url}>{url}</a></Title>
          </Paragraph>
        </Col>
      </Row>
    </Drawer>
    <List
      itemLayout="vertical"
      size="large"
      pagination={{
        onChange: page => {
          console.log(page);
        },
        pageSize: 5,
      }}
    dataSource={FeedsGoogleNewsTopic}

    renderItem={item => (
      <List.Item
      key={item.title}
      actions={[
        <IconText icon={StarOutlined} text={item.pubDate} key="list-vertical-star-o" />,
        // <IconText icon={LikeOutlined} text="156" key="list-vertical-like-o" />,
        // <IconText icon={MessageOutlined} text="2" key="list-vertical-message" />,
      ]}
      // extra={
      //   <img
      //     width={100}
      //     alt="logo"
      //     src={img}
      //   />
      // }
      >
        <List.Item.Meta
          avatar={<Avatar style={{ backgroundColor: '#1890ff' }} icon={<AntDesignOutlined />} src={item.avatar} />}
          title={<a href={item.link}>{item.title}</a>}
          description={item.source }
        />
        
        <Card
          hoverable
          // style={{ width: 600, textAlign:'center' }}
          cover={<img alt="example" src={item.ogImage } />}
          // actions={[
          //   // <SettingOutlined key="setting" />,
          //   //<EditOutlined key="edit" />,
          //   // <EyeOutlined  key="ver"
            
          //   />,
          //   // <CopyOutlined  key="copiar" />
          //   // <EllipsisOutlined key="ellipsis" />,
          // ]}
          // onClick={showDrawer(item.summary)}
          //onClick={() => showDrawer(item.summary,item.title,item.link,item.pubdate)}
        >
          
          {/* <Title level={5}>{item.title}</Title> */}
        </Card>
        <Title level={5} >
        <p dangerouslySetInnerHTML={{__html: item.summary}} /> 
        </Title>
        {/* <div dangerouslySetInnerHTML={{__html: item.description}} /> */}
      </List.Item>
    )}
  />
    </Spin>
  );
};
const mapStateToProps = (state) => {
  return {
    searching: state.topic.searching,
    FeedsGoogleNewsTopic: state.topic.datos_googlenews_topic,
    topic:state.topic.topic,
  }
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      dataGoogleNewsTopic: handleFeedsGoogleNewsTopic,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(GoogleNewsTopic);