import React, { useEffect, useState} from 'react';
import { Spin, Space, Input, Radio, Card, List, Typography, Drawer, Row, Col, Avatar, message } from 'antd';
import { connect } from 'react-redux';
import CustomTable from '../../components/DataDisplay/Table';
import { bindActionCreators } from 'redux';
import { handleFeedsGoogleNews } from '../../redux/actions/googlenews';
import { EditOutlined, EllipsisOutlined, SettingOutlined, EyeOutlined, CopyOutlined, AntDesignOutlined, StarOutlined, LikeOutlined, MessageOutlined } from '@ant-design/icons';
import img from './../../styles/images/5985.jpg';


const GoogleNews = (props) => {
  const {Search} = Input
  const { Meta } = Card;
  const { Title, Paragraph, Text } = Typography;
  const { searching, onPageLoad, datos, tipo, onChangeTipo, dataGoogleNews, FeedsGoogleNews } = props;
  // console.log('Tuvergonzolog: Ordenes -> datos',  datos);
  const [visible, setVisible] = useState(false);
  const [summary, setSummary] = useState('');
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const [date, setDate] = useState('');

  //onPageLoad()
  useEffect(() => {
    // dataGoogleNews({search_data:''});
  }, []);
  const showDrawer = (feed,title_,url_, date_) => {
    // console.log('TuvergonzoLog: showDrawer -> feed', title_)
    setDate(date_);
    setUrl(url_);
    setTitle(title_);
    setSummary(feed);
    setVisible(!visible);
  };
  const onClose = () => {
    showDrawer();
  }
  const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );
  const validatetopic = (value) =>{

    const {search_data} = value
    console.log('TuvergonzoLog: validatetopic -> value', search_data)
    if(search_data===''){
      message.error('Ingrese el Topic');
    }else{
      dataGoogleNews(value)
    }
  }
  return (
    <Spin tip="Cargando..." spinning={searching}>
      {/* <Radio.Group onChange={(e)=>{onChangeTipo(e.target.value)}} defaultValue={tipo} buttonStyle="solid">
        <Radio.Button   value="url">URL</Radio.Button>
        <Radio.Button value="feed">Feed</Radio.Button>
      </Radio.Group> */}
      <div className="searchBar">
        <Search
          placeholder="Ingrese Topic"
          enterButton="Buscar y agregar"
          size="large"
          onSearch={(value) => validatetopic({search_data:value})}
        />
      </div>
      <br></br>
      {/* <CustomTable columns={columns}
      expandable={{
        expandedRowRender: record => <p style={{ margin: 0 }}>{record.summary}</p>,
        rowExpandable: record => record.title !== 'Not Expandable',
      }}
      data={datos} /> */}
    <Drawer
      title="Note"
      width={600}
      onClose={() => onClose()}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          {/* <!--Footer> */}
        </div>
      }
    >
      <Row>
        <Col span={24}>

        <Paragraph>
         <Title level={4}>Title:</Title>
        <Title level={5}>{title}</Title>
        <div>Date:{date}</div>
        </Paragraph>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
        <Paragraph>
        <Title level={4}>Summary:</Title>
          <div dangerouslySetInnerHTML={{__html: summary}} />
        </Paragraph>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Paragraph>
            <Title level={4}>URL</Title>
          <Title level={5}><a  target="_blank" href={url}>{url}</a></Title>
          </Paragraph>
        </Col>
      </Row>
    </Drawer>
    <List
      itemLayout="vertical"
      size="large"
      pagination={{
        onChange: page => {
          console.log(page);
        },
        pageSize: 20,
      }}
    dataSource={FeedsGoogleNews}

    renderItem={item => (
      <List.Item
      key={item.title}
      actions={[
        <IconText icon={StarOutlined} text={item.pubDate} key="list-vertical-star-o" />,
        // <IconText icon={LikeOutlined} text="156" key="list-vertical-like-o" />,
        // <IconText icon={MessageOutlined} text="2" key="list-vertical-message" />,
      ]}
      // extra={
      //   <img
      //     width={100}
      //     alt="logo"
      //     src={img}
      //   />
      // }
      >
        <List.Item.Meta
          avatar={<Avatar style={{ backgroundColor: '#1890ff' }} icon={<AntDesignOutlined />} src={item.avatar} />}
          title={<a href={item.link}>{item.title}</a>}
          description={item.source +' . '+ item.hours}
        />
        <Title level={5} >
        <p dangerouslySetInnerHTML={{__html: item.summary}} /> 
        </Title>
        {/* <div dangerouslySetInnerHTML={{__html: item.description}} /> */}
      </List.Item>
    )}
  />
    </Spin>
  );
};
const mapStateToProps = (state) => {
  return {
    searching: state.googlenews.searching,
    FeedsGoogleNews: state.googlenews.datos_googlenews,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      dataGoogleNews: handleFeedsGoogleNews,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(GoogleNews);