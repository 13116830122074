import { 
  GOOGLE_NEWS_STARTE, 
  GOOGLE_NEWS_SUCCESS
} from '../actions/constants'

const initialState = {
   searching: false,
   datos_googlenews:[{
     title:'',
     description:'',
   }],
}

export default function feedsReducer(state = initialState, action) {
   switch (action.type) {
      case GOOGLE_NEWS_STARTE:
      return Object.assign({}, state, {
         searching: true,
      })
      case GOOGLE_NEWS_SUCCESS:
         return Object.assign({}, state, {
            success: true,
            datos_googlenews:action.payload,
            searching: false,
         })
      default:
         return state
   }
}