import React, { useEffect, useState} from 'react';
import { Spin, Space, Input, Radio, Card, List, Typography, Drawer, Row, Col } from 'antd';
import { connect } from 'react-redux';
import CustomTable from '../../components/DataDisplay/Table';
import { bindActionCreators } from 'redux';
import { handleFeeds } from '../../redux/actions/creators';
import { EditOutlined, EllipsisOutlined, SettingOutlined, EyeOutlined, CopyOutlined } from '@ant-design/icons';
import img from './../../styles/images/5985.jpg';


const Feed = (props) => {
  const {Search} = Input
  const { Meta } = Card;
  const { Title, Paragraph, Text } = Typography;
  const { searching, onPageLoad, datos, tipo, onChangeTipo } = props;
  // console.log('Tuvergonzolog: Ordenes -> datos',  datos);
  const [visible, setVisible] = useState(false);
  const [summary, setSummary] = useState('');
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const [date, setDate] = useState('');

  //onPageLoad()
  useEffect(() => {
    onPageLoad();
  }, []);
  const showDrawer = (feed,title_,url_, date_) => {
    // console.log('TuvergonzoLog: showDrawer -> feed', title_)
    setDate(date_);
    setUrl(url_);
    setTitle(title_);
    setSummary(feed);
    setVisible(!visible);
  };
  const onClose = () => {
    showDrawer();
  }

  return (
    <Spin tip="Cargando..." spinning={searching}>
      {/* <Radio.Group onChange={(e)=>{onChangeTipo(e.target.value)}} defaultValue={tipo} buttonStyle="solid">
        <Radio.Button   value="url">URL</Radio.Button>
        <Radio.Button value="feed">Feed</Radio.Button>
      </Radio.Group> */}
      {/* <div className="searchBar">
        <Search
          placeholder="Ingrese URL"
          enterButton="Buscar"
          size="large"
          onSearch={(value) => onPageLoad({value,tipo})}
        />
      </div>
       */}
      {/* <CustomTable columns={columns}
      expandable={{
        expandedRowRender: record => <p style={{ margin: 0 }}>{record.summary}</p>,
        rowExpandable: record => record.title !== 'Not Expandable',
      }}
      data={datos} /> */}
    <Drawer
      title="Note"
      width={600}
      onClose={() => onClose()}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          {/* <!--Footer> */}
        </div>
      }
    >
      <Row>
        <Col span={24}>

        <Paragraph>
         <Title level={4}>Title:</Title>
        <Title level={5}>{title}</Title>
        <div>Date:{date}</div>
        </Paragraph>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
        <Paragraph>
        <Title level={4}>Summary:</Title>
          <div dangerouslySetInnerHTML={{__html: summary}} />
        </Paragraph>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Paragraph>
            <Title level={4}>URL</Title>
          <Title level={5}><a  target="_blank" href={url}>{url}</a></Title>
          </Paragraph>
        </Col>
      </Row>
    </Drawer>
    <List
    grid={{
      gutter: 16,
      xs: 1,
      sm: 2,
      md: 4,
      lg: 4,
      xl: 4,
      xxl: 3,
    }}
    dataSource={datos}
    renderItem={item => (
      <List.Item>
        {/* <Card title={item.title}>Card content</Card> */}
              <Card
          hoverable
          style={{ width: 240 }}
          cover={<img alt="example" src={item.img == '' ? img: item.img } />}
          actions={[
            // <SettingOutlined key="setting" />,
            //<EditOutlined key="edit" />,
            <EyeOutlined  key="ver"
            
            />,
            <CopyOutlined  key="copiar" />
            // <EllipsisOutlined key="ellipsis" />,
          ]}
          // onClick={showDrawer(item.summary)}
          onClick={() => showDrawer(item.summary,item.title,item.link,item.pubdate)}
        >
          
          <Title level={5}>{item.title}</Title>
        </Card>
      </List.Item>
    )}
  />
    </Spin>
  );
};
const mapStateToProps = (state) => {
  return {
    searching: state.feeds.searching,
    datos: state.feeds.datos,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onPageLoad: handleFeeds,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Feed);