import * as constants from './constants';
import * as api from '../../config';
import axios from 'axios';

export const handleCollapseSidebar = (payload) => ({
  type: constants.TOGGLED_SIDEBAR,
  payload,
});

/** login */
export const handleUserLogin = () => ({
  type: constants.USER_LOGIN_STARTED,
});

export const userLoginFailed = (payload) => ({
  type: constants.USER_LOGIN_FAILED,
  payload,
});

const setAuthorizationToken = (token) => {
  if (token) {
    axios.defaults.headers.common.Authorization = token;

    localStorage.setItem('token', token);
  } else {
    delete axios.defaults.headers.common.Authorization;

    localStorage.removeItem('token');
  }
};

export const userLoginSuccess = ({ username, token, role }) => {
  setAuthorizationToken(token);

  return {
    type: constants.USER_LOGIN_SUCCESS,
    payload: {
      username,
      token,
      role,
    },
  };
};

export const verifyUserCredentials = (payload) => (
  dispatch,
  getState,
  axiosHandler
) => {
  dispatch(handleUserLogin());

  const { username, password } = payload;

  axiosHandler.getUser = async () => {
    await axiosHandler
      .post(api.AUTH_USER, {
        email: username,
        password: password,
      })
      .then((res) => {
        const { success, message, token, firstName, lastName, role } = res.data;

        if (!success) {
          dispatch(
            userLoginFailed({
              message,
              title: `Atención ${username}`,
            })
          );
        } else {
          dispatch(
            userLoginSuccess({
              username: `${firstName} ${lastName}`,
              token,
              role,
            })
          );
        }
      })
      .catch((err) => {
        console.log('Console: getUser -> err', err);
      });
  };

  axiosHandler.getUser();
};

export const handleUserLogout = () => (dispatch) => {
  setAuthorizationToken(false);

  dispatch({
    type: constants.USER_HAS_LOGOUT,
  });
};

/** Usuarios */
export const handleUserQuery = () => (dispatch, getState, axiosHandler) => {
  axiosHandler.getUsers = async () => {
    await axiosHandler.get(api.USER_LIST);
  };
};

/**
 * Feeds 
 */
export const handleFeedUrlSumary = (payload) => (dispatch, getState, axiosHandler) => {
  dispatch({
    type: constants.FEED_URL_SUMARY_STARTE,
  })
  axiosHandler.getFeeds = async () => {
    await axiosHandler
      .post(api.FEED_URLSUMARY,{url:payload.value, tipo:payload.tipo})
      .then((res) => {
        console.log('Console: axiosHandler.getOrdenes -> res', res);
        dispatch({
          type: constants.FEED_URL_SUMARY,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log('Tuvergonzolog: axiosHandler.getOrdenes -> err', err);
      });
  };
  axiosHandler.getFeeds();
};
export const changeTipo = (payload)=>({
  type:constants.TIPO_URL_FEED,
  payload
})

/**
 * Feeds db locales
 */
export const handleFeeds = (payload) => (dispatch, getState, axiosHandler) => {
  dispatch({
    type: constants.FEED_LOCAL_STARTE,
  })
  axiosHandler.getFeedsLocal = async () => {
    await axiosHandler
      .get(api.FEED_LOCAL)
      .then((res) => {
      console.log('TuvergonzoLog: axiosHandler.getFeedsLocal -> res', res)
        dispatch({
          type: constants.FEED_LOCAL,
          payload: res.data,
        });
      })
      .catch((err) => {
      console.log('TuvergonzoLog: axiosHandler.getFeedsLocal -> err', err)
      });
  };
  axiosHandler.getFeedsLocal();
};

