import * as constants from './constants';
import * as api from '../../config';
import axios from 'axios';

/**
 * Feeds Google News
 */
export const handleFeedsGoogleNewsTopic = (payload) => (dispatch, getState, axiosHandler) => {
  console.log('TuvergonzoLog: handleFeedsGoogleNewsTopic -> payload', payload)
  const search_data = payload
  dispatch({
    type: constants.GOOGLE_NEWS_TOPIC_STARTE,
  })
  axiosHandler.getFeeds = async () => {
    await axiosHandler
      .get(api.GOOGLE_NEWS_FEEDS_TOPIC+search_data)
      .then((res) => {
        //console.log('Console: axiosHandler.getOrdenes -> res', res);
        dispatch({
          type: constants.GOOGLE_NEWS_TOPIC_SUCCESS,
          // payload: res.data,
          payload: res.data.message=='No hay feeds que mostrar'? []:res.data,
          topic:search_data
        });
      })
      .catch((err) => {
        console.log('Tuvergonzolog: axiosHandler.getOrdenes -> err', err);
      });
  };
  axiosHandler.getFeeds();
};