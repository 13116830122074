import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import Dashboard from '../dashboard';
import Catalogos from '../Admin/Catalogos';
import LayoutHome from './home';
import Usuarios from '../Admin/Users';
import Feed from '../Feed'
import GoogleNews from '../GoogleNews'
import GoogleNewsTopic from '../Topic'

class Application extends React.Component {
  render() {
    return (
      <Dashboard>
        <Switch>
          <Route path="/app" exact component={LayoutHome} />
          <Route path="/app/feed" component={Feed} />
          <Route path="/app/news" component={GoogleNews} />
          <Route path="/app/topic" component={GoogleNewsTopic} />
          {/* <Route path="/app/transporte" component={Transporte} />
          <Route path="/app/farmacia" component={Farmacia} />
          <Route path="/app/ordenes" component={Ordenes} />
          <Route path="/app/admin/catalogos" component={Catalogos} />
          <Route path="/app/admin/usuarios" exact component={Usuarios} /> */}
        </Switch>
      </Dashboard>
    );
  }
}

export default withRouter(Application);
