import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Input, Button, Checkbox, notification, Spin } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';

import { verifyUserCredentials } from '../../../redux/actions/creators';

const NormalLoginForm = (props) => {
  const {
    doVerifyUserCredentials,
    label,
    fetching,
    success,
    notify,
    notificationMessage,
    notificationTitle,
    history,
  } = props;

  useEffect(() => {
    if (notify) {
      openNotification('warning');
    }

    if (success) {
      // window.location.href = '/';
      history.push('/app');
    }
  }, [notify, success]);

  const openNotification = (type) => {
    notification[type]({
      message: notificationTitle,
      description: notificationMessage,
      placement: 'bottomRight',
    });
  };

  return (
    <Form
      name="normal_login"
      className="login-form"
      onFinish={doVerifyUserCredentials}
    >
      <Form.Item
        name="username"
        rules={[
          {
            required: true,
            message: 'Por favor ingrese el usuario!',
          },
        ]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Correo electrónico"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Por favor ingrese la contraseña!',
          },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Contraseña"
        />
      </Form.Item>
      <Form.Item>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>Recordárme</Checkbox>
        </Form.Item>

        <a className="login-form-forgot" href="#!">
          ¿Olvidaste tu contraseña?
        </a>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          disabled={fetching}
        >
          {label}
        </Button>
      </Form.Item>
      {fetching && (
        <div className="custom-spin">
          <Spin />
        </div>
      )}
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    fetching: state.login.fetching,
    label: state.login.label,
    success: state.login.success,
    notify: state.login.notify,
    notificationMessage: state.login.notificationMessage,
    notificationTitle: state.login.notificationTitle,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      doVerifyUserCredentials: verifyUserCredentials,
    },
    dispatch
  );
};

const RouterNormalLoginForm = withRouter(NormalLoginForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RouterNormalLoginForm);
