import React from 'react';
import { Layout } from 'antd';
import jwtDecode from 'jwt-decode';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AccessLanding from '../access/AccessLanding';
import Application from './Application';

import { handleUserLogout } from '../../redux/actions/creators';

class BaseLayout extends React.Component {
  render() {
    const { onUserLogout } = this.props;

    let authenticated = false;

    if (localStorage.getItem('token')) {
      let currentUser = null;

      currentUser = jwtDecode(localStorage.getItem('token'));

      const currentTime = Date.now() / 1000;

      if (currentUser.exp < currentTime) {
        onUserLogout();
        window.location.href = '/';
      }

      authenticated = true;
    }

    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Router>
          <Route path="/" exact>
            {authenticated ? <Redirect to="/app" /> : <AccessLanding />}
          </Route>
          <Route path="/app" component={Application} />
        </Router>
      </Layout>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onUserLogout: handleUserLogout,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(BaseLayout);
